import { logout } from '@/redux/slice/auth/authSlice';
import toast from 'react-hot-toast';

// import { useHistory } from "react-router-dom";

export const globalMiddleware = (store) => (next) => (action) => {
  if (action.error && action.payload && action.payload.status === 401) {
    // Dispatch logout action
    store.dispatch(logout());

    // toast.error("Session expired. Please log in again.");
  }
  // else if (action.error && action.payload && action.payload.status === 422) {
  //   toast.error("please fill Required field");
  // }
  if (action.type.endsWith('fulfilled')) {
    if (action?.payload?.msgArabic) {
      toast.success(action?.payload?.msgArabic);
    }
  } else if (action.type.endsWith('rejected')) {
    if (action?.payload?.data?.msgArabic) {
      // toast.error(action?.payload?.data?.msgArabic);
    }
  }

  return next(action);
};
