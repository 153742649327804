import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editor: null,
  title: '',
  subTitle: '',
  coverImage: '',
  content: null,
  categories:[]
};

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setEditor: (state, action) => {
      state.editor = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setSubTitle: (state, action) => {
      state.subTitle = action.payload;
    },
    setCoverImage: (state, action) => {
      state.coverImage = action.payload;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setCategories:(state,action)=>{
      state.categories = action.payload
    },
    resetArticle: (state) => {
      state.title = '';
      state.subTitle = '';
      state.coverImage = null;
      state.categories = [];
      // Add any other fields that need to be reset
    }
  },
});

export const { setEditor, setTitle, setSubTitle, setCoverImage, setContent,setCategories,resetArticle } = articleSlice.actions;
export default articleSlice.reducer;