import { createApi } from '@reduxjs/toolkit/query/react';

import baseQuery from '../baseQuery';

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: baseQuery,

  tagTypes: ['Post', 'Posts', 'PostComments', 'NewsFeed', 'CommentReplies'],

  endpoints: (builder) => ({
    createPost: builder.mutation({
      query: (postData) => ({
        url: '/posts',
        method: 'POST',
        body: postData,
      }),
      async onQueryStarted(postData, { dispatch, queryFulfilled }) {
        try {
          const { data: createdPost } = await queryFulfilled;

          dispatch(
            postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
              draft.posts.unshift(createdPost.post);
            })
          );
          dispatch(
            postsApi.util.updateQueryData(
              'getMyPostsFeed',
              undefined,
              (draft) => {
                draft.posts.unshift(createdPost.post);
              }
            )
          );
        } catch {}
      },
    }),

    getNewsFeed: builder.query({
      query: ({ page = 1, interest }) =>
        `/posts/feed?page=${page}&limit=${12}&category=${interest}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        if (
          newItems.pagination.currentPage <= currentCache.pagination.currentPage
        )
          currentCache.posts = [];
        if (
          currentCache.posts.length > 40 &&
          newItems.pagination.currentPage != newItems.pagination.totalPages
        ) {
          // remove first tweleve items
          currentCache.posts.splice(0, 15);
        }
        currentCache.posts.push(...newItems.posts);
        currentCache.pagination = newItems.pagination;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['NewsFeed'],
    }),

    getPostById: builder.query({
      query: (postId) => `/posts/${postId}`,
      providesTags: (result, error, postId) => [{ type: 'Post', id: postId }],
    }),

    updatePost: builder.mutation({
      query: ({ postId, updatedData }) => ({
        url: `/posts/${postId}`,
        method: 'PUT',
        body: updatedData,
      }),
      async onQueryStarted(
        { postId, updatedData },
        { dispatch, queryFulfilled }
      ) {
        dispatch(
          postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
            // find the post to update in draft.posts
            const postToUpdate = draft.posts.find(
              (post) => post._id === postId
            );

            if (postToUpdate) {
              Object.assign(postToUpdate.content, updatedData.content);
            }
          })
        );
        dispatch(
          postsApi.util.updateQueryData(
            'getMyPostsFeed',
            undefined,
            (draft) => {
              // find the post to update in draft.posts
              const postToUpdate = draft.posts.find(
                (post) => post._id === postId
              );

              if (postToUpdate) {
                Object.assign(postToUpdate.content, updatedData.content);
              }
            }
          )
        );

        dispatch(
          postsApi.util.updateQueryData('getPostById', postId, (draft) => {
            Object.assign(draft.post.content, updatedData.content);
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deletePost: builder.mutation({
      query: (postId) => ({
        url: `/posts/${postId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(postId, { dispatch, queryFulfilled }) {
        dispatch(
          postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
            // find the index of the post to delete it from in draft.posts
            const postIndex = draft.posts.findIndex(
              (post) => post._id === postId
            );

            if (postIndex !== -1) {
              draft.posts.splice(postIndex, 1);
            }
          })
        );
        dispatch(
          postsApi.util.updateQueryData(
            'getMyPostsFeed',
            undefined,
            (draft) => {
              const postIndex = draft.posts.findIndex(
                (post) => post._id === postId
              );

              if (postIndex !== -1) {
                draft.posts.splice(postIndex, 1);
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    likePost: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `/posts/${postId}/like`,
        method: 'POST',
      }),
      async onQueryStarted({ postId, userId }, { dispatch, queryFulfilled }) {
        dispatch(
          postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
            // find the post to update in draft.posts
            const postToUpdate = draft.posts.find(
              (post) => post._id === postId
            );
            if (postToUpdate) {
              postToUpdate.likes.push({
                user: userId,
                post: postId,
              });
            }
          })
        );
        dispatch(
          postsApi.util.updateQueryData(
            'getMyPostsFeed',
            undefined,
            (draft) => {
              // find the post to update in draft.posts
              const postToUpdate = draft.posts.find(
                (post) => post._id === postId
              );
              if (postToUpdate) {
                postToUpdate.likes.push({
                  user: userId,
                  post: postId,
                });
              }
            }
          )
        );

        dispatch(
          postsApi.util.updateQueryData('getPostById', postId, (draft) => {
            draft.post.likes.push({
              user: userId,
              post: postId,
            });
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    unlikePost: builder.mutation({
      query: ({ postId, userId }) => ({
        url: `/posts/${postId}/like`,
        method: 'POST',
      }),
      async onQueryStarted({ postId, userId }, { dispatch, queryFulfilled }) {
        dispatch(
          postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
            // find the post to update in draft.posts
            const postToUpdate = draft.posts.find(
              (post) => post._id === postId
            );
            if (postToUpdate) {
              postToUpdate.likes = postToUpdate.likes.filter(
                (like) => like.user !== userId
              );
            }
          })
        );
        dispatch(
          postsApi.util.updateQueryData(
            'getMyPostsFeed',
            undefined,
            (draft) => {
              // find the post to update in draft.posts
              const postToUpdate = draft.posts.find(
                (post) => post._id === postId
              );
              if (postToUpdate) {
                postToUpdate.likes = postToUpdate.likes.filter(
                  (like) => like.user !== userId
                );
              }
            }
          )
        );

        dispatch(
          postsApi.util.updateQueryData('getPostById', postId, (draft) => {
            draft.post.likes = draft.post.likes.filter(
              (like) => like.user !== userId
            );
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getPostComments: builder.query({
      query: (postId) => `/posts/${postId}/comments`,
      providesTags: (result, error, postId) => [
        { type: 'PostComments', id: postId },
      ],
    }),

    addPostComment: builder.mutation({
      query: ({ postId, comment }) => ({
        url: `/posts/${postId}/comments`,
        method: 'POST',
        body: { content: comment },
      }),
      invalidatesTags: (result, error, { postId }) => [
        { type: 'PostComments', id: postId },
      ],
    }),

    replyToComment: builder.mutation({
      query: ({ postId, commentId, reply }) => ({
        url: `/posts/${postId}/comments/${commentId}/reply`,
        method: 'POST',
        body: { content: reply },
      }),
      invalidatesTags: (result, error, { postId, commentId }) => [
        { type: 'PostComments', id: postId },
        { type: 'CommentReplies', id: commentId },
      ],
    }),

    resharePost: builder.mutation({
      query: ({ postId, postData }) => ({
        url: `/posts/${postId}/reshare`,
        method: 'POST',
        body: postData,
      }),
      async onQueryStarted({ postId, postData }, { dispatch, queryFulfilled }) {
        try {
          const { data: createdPost } = await queryFulfilled;
          console.log('createdPost', createdPost);
          dispatch(
            postsApi.util.updateQueryData('getNewsFeed', undefined, (draft) => {
              draft.posts.unshift(createdPost.post);
              const postToUpdate = draft.posts.find(
                (post) => post._id === postId
              );
              if (postToUpdate) {
                postToUpdate.reshares.push(createdPost.post._id);
              }
            })
          );
          dispatch(
            postsApi.util.updateQueryData(
              'getMyPostsFeed',
              undefined,
              (draft) => {
                draft.posts.unshift(createdPost.post);
                const postToUpdate = draft.posts.find(
                  (post) => post._id === postId
                );
                if (postToUpdate) {
                  postToUpdate.reshares.push(createdPost.post._id);
                }
              }
            )
          );
        } catch {}
      },
    }),
    getPostsByUserId: builder.query({
      query: ({ page, userId }) => {
        console.log('userId', userId);
        return `/posts/userPosts?page=${page}&limit=${3}&userId=${userId}`;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        if (newItems.pagination.currentPage === 1) currentCache.posts = [];
        currentCache.posts.push(...newItems.posts);
        currentCache.pagination = newItems.pagination;
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    // Edit a comment
    editComment: builder.mutation({
      query: ({ postId, commentId, content }) => ({
        url: '/posts/editComment',
        method: 'PUT',
        body: { postId, commentId, content },
      }),
      invalidatesTags: (result, error, { postId }) => [
        { type: 'PostComments', id: postId },
      ],
    }),

    // Delete a comment
    deleteComment: builder.mutation({
      query: ({ postId, commentId }) => ({
        url: '/posts/deleteComment',
        method: 'DELETE',
        body: { postId, commentId },
      }),
      invalidatesTags: (result, error, { postId }) => [
        { type: 'PostComments', id: postId },
      ],
    }),

    // Like/Unlike a comment
    likeComment: builder.mutation({
      query: ({ postId, commentId }) => ({
        url: '/posts/likeComment',
        method: 'POST',
        body: { postId, commentId },
      }),
      invalidatesTags: (result, error, { postId }) => [
        { type: 'PostComments', id: postId },
      ],
    }),
  }),
});

export const {
  useCreatePostMutation,
  useGetNewsFeedQuery,
  useGetPostByIdQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
  useLikePostMutation,
  useUnlikePostMutation,
  useGetPostCommentsQuery,
  useAddPostCommentMutation,
  useReplyToCommentMutation,
  useResharePostMutation,
  useGetPostsByUserIdQuery,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useLikeCommentMutation,
} = postsApi;
